import { showUnexpectedError } from 'packages/lib/toast';
import { useEffect, useRef } from 'react';
import { Id } from 'react-toastify';
import { useAuthNavigation, LoginRoutes } from '../../../routes/routes-config';

export default function ActiveDirectoryCallbackError() {
  const navigate = useAuthNavigation();
  const toastId = useRef<Id>();

  useEffect(() => {
    if (toastId.current) return;

    toastId.current = showUnexpectedError(new Error('Error from Active Directory'));
    navigate(LoginRoutes.ActiveDirectory);
  }, []);

  return null;
}
